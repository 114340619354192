<template>
  <div class="contactUsPage">
    <div class="mainContainer">
      <TopMenu :show-logo="true"/>
      <div class="contactUsBox">
        <div class="title">قم بملئ الحقول أدناه</div>
        <DuInput
          label="الاسم"
          :is-required="true"
          is-required-error-message="رجاء قم بكتابة اسمك"
          type="text"
          @input="(e) => state.name = e.value"
          :get-ref="(e) => state.formRefs.push(e)"
        />
        <DuInput
          label="البريد الإلكتروني"
          :is-required="true"
          is-required-error-message="رجاء قم بكتابة بريدك الإلكتروني"
          type="email"
          type-error-message="هذا البريد غير صالح"
          @input="(e) => state.email = e.value"
          :get-ref="(e) => state.formRefs.push(e)"
        />
        <DuInput
          label="الرسالة"
          :is-required="true"
          is-required-error-message="قم بكاتبة الرسالة هنا"
          type="textarea"
          @input="(e) => state.message = e.value"
          :get-ref="(e) => state.formRefs.push(e)"
        />
        <DuButton text="إرسال" @click="send()" class="sendButton"/>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import DuInput from "../components/DuInput";
import {onMounted, reactive} from "vue";
import DuButton from "../components/DuButton";
import AjaxWorker from "../helpers/AjaxWorker";
import FixedNotification from "../components/plugins/fixedNotifications/FixedNotification";
import BrowserHelper from "../helpers/BrowserHelper";
export default {
  name: "ContactUs",
  components: {DuButton, DuInput, Footer, TopMenu},
  setup() {
    const state = reactive({
      name: '',
      email: '',
      message: '',
      formRefs: [],
      isSending: false,
    });
    const send = () => {
      let isFormValid = true;
      state.formRefs.forEach((el) => {
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
      });
      if(isFormValid) {
        state.isSending = true;
        new AjaxWorker().request("/transactions.php?action=sendContactUsMessage", {
          message: state.message,
          email: state.email,
          name: state.name,
        }).then((res) => {
          if(res === "NOINTERNETCONNECTION" || !res.state) {
            FixedNotification.create({
              decription: "حدث خطأ غير معروف , حاول مجددًا",
              icon: "errorF",
              color: 'red',
              hideAfter: 3000
            });
          } else {
            FixedNotification.create({
              title: "تم ارسال الرسالة",
              decription: "سنقوم بالرد عليك قريبًا",
              icon: "trueF",
              color: 'green',
              hideAfter: 5000
            });
            state.formRefs.forEach((el) => el.state.innerValue = "");
          }
        });
      }
    }
    onMounted(() => {
      BrowserHelper.changeTitle('تواصل معنا');
    });
    return {
      state,
      send,
    }
  }
}
</script>

<style scoped>
.contactUsPage{
  background-color: #000;
  width: 100%;
  min-height: 100%;
  background-image: url("../assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.contactUsBox{
  width: 50%;
  padding: 20px 2.5%;
  background: #fff;
  margin: 50px 20%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  row-gap: 10px;
}

.contactUsBox .title{
  width: available;
  padding: 10px 0;
  color: #555;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  text-align: start;
}
.contactUsBox .sendButton{
  width: auto;
  place-self: end;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .contactUsBox {
    width: 80%;
    padding: 20px 5%;
    margin: 50px 5%;
  }
}
</style>